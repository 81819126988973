import React, { useEffect, useState, useMemo } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import {NotificationContainer, NotificationManager} from 'react-notifications';


import Alert from '@mui/material/Alert';
import axios from 'axios';
import { useRef } from 'react';

const url = "http://localhost:3030"

function InputFile({placeholder, handleChangeImage}){
    const fileRef = useRef()

    const onHandleChangeImage = ()=>{
      handleChangeImage(fileRef.current.files[0])
    }

    return (<div class="grid grid-cols-1 mt-5 mx-7">
              <form action="#" enctype="multipart/form-data">
              <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold mb-1">Charger Photo</label>
                <div class='flex items-center justify-center w-full'>
                    <label class='flex flex-col border-4 border-dashed w-full h-32 hover:bg-gray-100 hover:border-blue-300 group'>
                        <div class='flex flex-col items-center justify-center pt-7'>
                          <svg class="w-10 h-10 text-blue-400 group-hover:text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                          <p class='lowercase text-sm text-gray-400 group-hover:text-blue-600 pt-1 tracking-wider'>{placeholder == "" ? "Selectionner une photo" : placeholder}</p>
                        </div>
                      <input type='file' name='image' ref={fileRef} onChange={onHandleChangeImage} class="hidden" />
                    </label>
              </div>
              </form>
          </div>
        )
}

function notify(msg){
  return () => NotificationManager.success(msg, 'Enregistrement', 3000);
  
}

function Service() {
    const columns = [
        { field: 'name', headerName: 'Nom', width: 150, editable: true },
        { field: 'description', headerName: 'Description', width: 300, editable: true },
        {
          field: "image",
          headerName: "Image",
          width: 200,
          renderCell: (params) => {
            // console.log(params);
            return (
              <>
                <Avatar src={url+"/"+params.value} />
                {params.value.username}
              </>
            );
          }
        }
      ];
    
    const [formulaire, setForm] = useState({
      name: "",
      description: "",
      image: ""
    })

    let success = false

    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [data, setData] = useState(null)
    const [pageSize, setPageSize] = useState(5)

  

    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    const handleEditRowsModelStop = React.useCallback((model) => {
        const id = Object.keys(editRowsModel)[0]
        console.log(editRowsModel[id])
        axios.patch(url+`/services/${id}`, editRowsModel[id])
    }, [editRowsModel]);

    const addService = () =>{
      console.log(formulaire)
      const formData = new FormData();
      formData.append( 
        "image", 
        formulaire.image,
      );
      formData.append('name', formulaire.name)
      formData.append('description', formulaire.description)
      formData.append('image_name', formulaire.image.name)

      if(Object.values(formulaire).length > 0){
        axios.post(url+"/services", formData, 
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res =>{
          notify("Enregistrement effectué avec succès")
          window.location.reload(false)
        }).catch(err => console.log(err))
      }
    }

    const handleInputChange = (e) =>{
      const field = e.target.name
      const value = e.target.value
      setForm(state =>({...state, [field]: value}))
    }


    const handleChangeImage = (name) =>{
      console.log(name)
      setForm((state) =>({...state, image: name}))
    }

    useEffect(() =>{
      let mounted = false;
        fetch(url+"/services").then(response => response.json()).then(res => {
            if(!mounted){
             console.log(res);
              setData(res)
            }
              
        }).catch(e => console.log(e))
        return () => mounted = true
    }, [])
  return (
    <div className='container-fluid' style={{margin: 'auto', width: "70%", marginTop: "10%"}}>
      
    
    <div style={{ height: 400, width: '100%' }}>
      <div className="row">
          <div className="col-sm-8">
              <DataGrid
              onCellEditStop={handleEditRowsModelStop}
              rows={data}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              editRowsModel={editRowsModel}
              onRowEditCommit={handleEditRowsModelStop}
              onEditRowsC
              onEditRowsModelChange={handleEditRowsModelChange}
          
          />
          </div>
          <div className="col-sm-4">
               {success && <div className="alert alert-success">
                  <p>Enregistrement effectué avec succès</p>
                </div>}
                <legend>Ajouter Service</legend>
                <div class="form-group">
                  <label for="">Nom</label>
                  <input type="text" class="form-control" onChange={handleInputChange} required name="name" id="" placeholder="Nom" />
                </div>

                <div class="form-group">
                  <label for="">Description</label>
                  <input type="text" class="form-control" onChange={handleInputChange} required name="description" id="" placeholder="Description" />
                </div>

                <InputFile placeholder={formulaire.image.name} handleChangeImage={handleChangeImage} />
              
                
              
                <button onClick={addService} class="btn btn-primary">Enregistrer</button>
              
          </div>
      </div>
    </div>
  </div>   
  )


}

export default Service