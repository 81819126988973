import React, { useState } from 'react';
import './Navbar.css';
import Header from './Header';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon
} from 'mdb-react-ui-kit';

export default function Navbar() {
  const [row, setRow] = useState(true)

  const responsive = () => {
    return setRow(window.innerWidth >= 960)
  }

  window.addEventListener('resize', responsive)
  return (
    <>
    <Header />
    <section class="header-area header-one">
      
      <div class="header-content-area ">
         <div class="container">
            <div class="row align-items-center">
               
               <div class="col-lg-6 col-12">
                  <div class="header-image d-none d-lg-block">
                     <div class="image">
                        {/* <img
                           src="https://cdn.ayroui.com/1.0/images/header/header-1.svg"
                           alt="Header"
                           /> */}
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-12 justify-content-center text-center ">
                  <div class="header-wrapper" style={{margin: 'auto'}}>
                     <div class="header-content card bg-dark opacity-75">
                        <h1 class="header-title text-2xl mt-2 opacity-100">
                           PRESENTATION
                        </h1>
                        <p class="text-lg opacity- m-auto pr-5 pl-5 pb-2">
                           AES strategy is to help our partners across our key market segments to modernize
                           their IT systems and realize their digital transformation goals with new technologies,
                           Agile methodologies, and consultant skills.
                        </p>
                     </div>
                     <div class="header-btn rounded-buttons justify-content-center">
                        <a
                           class="btn primary-btn-outline btn-lg bg-dark opacity-75 text-white"
                           href="#about"
                           >
                           More
                        </a>
                     </div>
                     
                  </div>
               </div>
            </div>
            
         </div>
         
         <div class="header-shape">
            <img src="https://cdn.ayroui.com/1.0/images/header/header-shape.svg" alt="shape" />
         </div>
      </div>
   </section> 
    </>
  );
}