import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Route, BrowserRouter } from 'react-router-dom';


import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import React, { useEffect, useState } from 'react'
import Service from './service/Service';
import Partner from './service/Partner';
import Login from './login/Login';
import useTokens from '../hooks/useTokens';

function Dashboard() {

    const {token, setToken } = useTokens()
    
    // if(!token){
    //     return <Login setToken={setToken}/>
    // }

   
  return (
    <BrowserRouter>
    <Route render={({ location, history }) => (
        <React.Fragment>
            <SideNav
                onSelect={(selected) => {
                    const to = '/' + selected;
                    if (location.pathname !== to) {
                        history.push(to);
                    }
                }}
                
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="services">
                    
                    <NavItem eventKey="services">
                        <NavIcon>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Services
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="partners">
                        <NavIcon>
                            <i className="fa fa-fw fa-briefcase" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Partners
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
            <main>
                <Route path="/aes-admin" exact component={props => <Service />} />
                <Route path="/services" component={props => <Service />} />
                <Route path="/partners" component={props => <Partner />} />
            </main>
        </React.Fragment>
    )}
    />
</BrowserRouter>
  )
}

export default Dashboard