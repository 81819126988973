import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import '@themesberg/flowbite';

import App from "./App"
import Login from './backoffice/login/Login';
import Dashboard from './backoffice/Dashboard';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* <App /> */}
      <Route exact path="/" component={App} />
      <Route exact path="/aes-admin" component={Dashboard} />
    </BrowserRouter>
  </React.StrictMode>
,
  document.getElementById('root')
);

