import React, { useMemo, useRef } from 'react';
import './Presentation2.css';
import ScrollAnimation from 'react-animate-on-scroll';
const values = [
   {
      name: 'Integrity',
      icon: 'fa-lightbulb'
   },
   {
      name: 'Commitment',
      icon: 'fa-angle-double-right'
   },
   {
      name: 'Safety',
      icon: 'fa-wrench'
   },
   {
      name: 'Excellence',
      icon: 'fa-certificate'
   },
   {
      name: 'Passion',
      icon: 'fa-angle-double-right'
   },
   {
      name: 'Innovation',
      icon: 'fa-angle-double-right'
   },
   {
      name: 'Teamwork',
      icon: 'fa-tasks'
   }
]
//  const values = ['Integrity', 'Commitment', 'Safety', 'Excellence', 'Passion', 'Innovation', 'Teamwork']
let k = 0;
let content = []

export default function Presentation2 (){
   const ref = useRef(null)

    return (

      <div class="w-full bg-center bg-cover" style={{backgroundImage: 'url(images/fondValues.jpg)'}}>
        <div class="flex items-center justify-center w-full h-full bg-green-900 bg-opacity-50">
            <div class="text-center">
                <h1 class="text-3xl mt-5 font-semibold text-white uppercase lg:text-5xl">OUR CORE <span class="">VALUE</span></h1>
                <ScrollAnimation animateIn="fadeInLeft" duration={1.5} delay={2}>
                  <img src="images/values.png" style={{marginLeft: "12%", marginBottom: "-10%", marginTop: "-10%"}} width="75%" alt="" srcset="" />
               </ScrollAnimation>
            </div>
        </div>
    </div>
    );
};
