import React, { useRef } from "react";


export default function Presentation() {
    const ref = useRef(null)

    return (
        <div id="about" className="container py-5"  >
            <div className="text-center" style={{textAlign: 'justify'}}>
                <h1 className="text-slate-500 font-medium mb-3 text-5xl text-center">ABOUT US</h1>
                <p className="text-slate-500 font-medium indent-8">
                    <span class="text-teal-600 text-md font-semibold"><strong>Our vision</strong></span> is to be a Leading Global IT Solution and Service provider with
                    Innovative Ideas and Methodology that will take Technology to its highest
                    level and closer to the people.<br/>
                    <span class="text-teal-600 text-md font-semibold"><strong>Our Mission</strong></span> is to help our partners to leverage the best technologies and drive
                    innovation.
                </p>
            </div>
        </div>
    )
}