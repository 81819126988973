import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Presentation from './components/Presentation';
import Presentation2 from './components/Presentation2';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Partners from './components/Partners';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import ScrollAnimation from 'react-animate-on-scroll';
import AdminPanel from './backoffice/AdminPanel';
import Service from './backoffice/service/Service';
import Dashboard from './backoffice/Dashboard';
import Login from './backoffice/login/Login';
import { useState } from 'react';
import useTokens from './hooks/useTokens';

function setToken(userToken){
  console.log(userToken)
  sessionStorage.setItem('token', JSON.stringify(userToken));
}
function getToken(){
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken
}

function App() {
 
   return <>
      
      <Navbar />
      <div className="container-fluid">
        <ScrollAnimation animateIn="fadeInDown" duration={2}>
            <Presentation />
        </ScrollAnimation>

        <ScrollAnimation animateIn="slideInRight" duration={2}>
            <Services />
        </ScrollAnimation>

        
            <Presentation2 />

        <ScrollAnimation animateIn="fadeInUp" duration={1.5} delay={2}>
            <Partners />
        </ScrollAnimation>

        {/* <ScrollAnimation animateIn="fadeIn" duration={3}>
          <About />
        </ScrollAnimation> */}
        
      </div>
        <Footer />
        
    </> 
}

export default App;
