import React from 'react'
import { Admin, Resource, fetchUtils } from 'react-admin';
import jsonapiClient  from 'ra-jsonapi-client';

import lb4Provider from 'react-admin-lb4';

import { UserList, ServiceEdit, ServiceCreate, ServiceIcon } from './services';
import { PartnerList, CreatePartner } from './partners';

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('Access-Control-Expose-Headers','Content-Range');
    return fetchUtils.fetchJson(url, options);
}

function AdminPanel() {
  return (
    <Admin dataProvider={jsonapiClient('http://localhost:1337/api')}>
        <Resource name="services" list={UserList} edit={ServiceEdit} create={ServiceCreate} icon={ServiceIcon}/>
        <Resource name="partners" list={PartnerList} create={CreatePartner}/>
    </Admin>
  )
}

export default AdminPanel