import * as React from 'react';

const routes = [
  {
    name: "ABOUT US",
    route: 'about'
  },
  {
    name: "OUR ACTIVITIES",
    route: 'activities'
  },
  {
    name: "OUR PARTNERS",
    route: 'partner'
  },
  {
    name: "CONTACT",
    route: 'contact'
  }
]
export default function Header (){
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [bgColor, setBackgroundColor] = React.useState('rgb(22, 78, 99)')

  const changeBackgroud = () => {
      if(window.scrollY >= 480)
        setBackgroundColor('#064e3b')
      else{
        setBackgroundColor('rgb(22, 78, 99)')
      }
  }

  window.addEventListener('scroll', changeBackgroud)
 

  return (
    <nav class="navbar navbar-expand-lg sticky-top " style={{backgroundColor: "white", color: "black"}}>
        <div class="container-fluid" >
        <div class="h-10 w-10 self-center mr-2">
        <a class="" href="#"><img class="h-10 w-10 self-center" src="images/logo-AES.png" />
      </a>
    </div>
          
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              {routes.map(r => <li class="nav-item">
                <a className='nav-link text-dark' href={`#${r.route}`}>{r.name}</a>
              </li>)}
            </ul>
          </div>
        </div>
    </nav>
  );
};
