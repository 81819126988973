import axios from 'axios';
import React, { useRef, useState } from 'react';
import config from "../config.json"


const url = config.SERVER_URL

function Contact() {
    const ref = useRef(null)
    const [formulaire, setFormulaire] = useState({
        name: '',
        telephone: '',
        email: '',
        message: ""
    })

    const handleChange = (e) =>{
        const field = e.target.name
        const value = e.target.value

        setFormulaire(state => ({...formulaire, [field]: value}))
    }

    const sendEmail = async (e) =>{
        e.preventDefault()
        const values = Object.values(formulaire)
        if(values.length > 0)
            await axios.post(url+"/mailing", formulaire).then(res =>{ 
                console.log(res)
            }).catch(err => console.log(err))
            console.log(ref)
    }
    return(
        <section id='contact' className='fade-in' >
<div class="relative w-50 m-auto justify-center min-h-screen sm:items-center sm:pt-0">
        {/* <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div class="mt-8 overflow-hidden">
                <div class="grid grid-cols-1 md:grid-cols-2"> */}
                    {/* <div class="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
                        <h1 class="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
                            Contacter nous !
                        </h1>
                        <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                            Remplissez le formulaire pour nous contacter 
                        </p>

                        <div class="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                                MERMOZ VDN LOT N 34 / DAKAR
                            </div>
                        </div>

                        <div class="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                                +221 78 187 01 47
                            </div>
                        </div>

                        <div class="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                                cantact@aeservice.digital
                            </div>
                        </div>
                    </div> */}
                    <div className='text-center mt-12 mb-5'>
                        <h1 className='text-gray-900 text-2xl text-white mb-2'>CONTACT US</h1>
                        
                    </div>
                    <form ref={ref}>
                        <div class="p-6 flex flex-col justify-center">
                            <div class="flex flex-col">
                                <label for="name" class="hidden">Name</label>
                                <input type="name" onChange={handleChange} name="name" id="name" placeholder="Name" class="w-100 mt-2 py-3 px-3 rounded-full bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                            </div>

                            <div class="flex flex-col mt-2">
                                <label for="email" class="hidden">Email</label>
                                <input type="email" onChange={handleChange} required name="email" id="email" placeholder="Email" class="w-100 mt-2 py-3 px-3 rounded-full bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                            </div>

                            <div class="flex flex-col mt-2">
                                <label for="tel" class="hidden">Phone number</label>
                                <input type="tel" onChange={handleChange} name="telephone" id="tel" placeholder="Phone number" class="w-100 mt-2 py-3 px-3 rounded-full bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                            </div>

                            <div class="flex flex-col mt-2">
                                <label for="tel" class="hidden">Message</label>
                                <textarea onChange={handleChange} required  name="message" className='form-control border border-gray-400 dark:border-gray-700 text-gray-800' placeholder='Message...' id="" cols="30" rows="10"></textarea>
                            </div>

                            <button onClick={sendEmail} class="md:w-32 bg-primary text-white font-bold py-2 px-6 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300">
                                Envoyer
                            </button>
                        </div>
                    </form>
                {/* </div>
            </div>
        </div> */}
    </div> 
        </section>
    );
}

export default Contact;
