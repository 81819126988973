import React, { useRef } from 'react';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Service from './Service';
import './Services.css';
import { Route } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useState } from 'react';
import { useEffect } from 'react';
import config from '../config.json'
import axios from 'axios';

const url = config.SERVER_URL

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const services = [
  {
  title: 'Business Applications',
  image: "images/image3.jpeg",
  content: ["ERP", "CRM", "EPM"]
 },
 {
  title: 'Data center Transformation',
  image: "images/datacenter_1.jpeg",
  content: ["Hybrid Cloud","Azure Cloud","Disaster Recovery","High Performance Computing","Infrastructure Management,Networking"]
 },
 {
  title: 'Security',
  image: "images/security.jpeg",
  content: ["Infrastructure Security", "Identity & Access Management", "Data Integrity", "Intelligent Surveillance"]
 },,
 {
  title: 'Support Services',
  image: "images/support.jpeg",
  content: ['Managed Services', 'IT Audit & Planning', 'Infrastructure Implementation', 'FastTrack Services', 'Training & Certifications']
 },
 {
  title: 'Modern Workplace',
  image: "images/modernworkplace.jpeg",
  content: ["Communication & Collaboration", "Mobility", "Productivity Apps", "Audio Visual"]
 },
 {
  title: 'Equipments',
  image: "images/equipement.jpeg",
  content: ['Electricity', 'Solar', 'Mechanic']
 },
 {
  title: 'Industries',
  image: "images/industrials.jpeg",
  content: ["Banking & Financial Services", "Utilities", "Oïl & Gas", "Public Sector", "Telco", "Retail"]
 }
]

export default function Services() {
  // const [services, setServices] = useState(services)

  // useEffect(() =>{
  //   let mounted = false;
  //   console.log(url)
  //     fetch(url+"/services").then(response => response.json()).then(res => {
  //         if(!mounted){
  //          setServices(res)
  //         }
            
  //     }).catch(e => console.log(e))
  //     return () => mounted = true
  // }, [])
const ref = useRef(null)
  return (
   
    <>
      <div id='activities' className="py-12 ml-xl-5 mr-xl-5"  >
          <h1 className='text-slate-500 font-medium mb-3 text-5xl text-center'>OUR ACTIVITIES</h1>
          <Carousel responsive={responsive} autoPlay={true} infinite={true} transitionDuration={600} dotListClass="custom-dot-list-style" containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]} itemClass="carousel-item-padding-40-px"
        >
            {services.map(s => <Service service={s} />)}
        </Carousel>
      </div>
    </>

  );
}

