import React, { useRef } from 'react';

import Carousel from 'react-multi-carousel';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  
const partners = [
    {
        name: 'partner 1',
        image: 'images/google.gif'
    },
    {
        name: 'Partner 2',
        image: 'images/alcatel.jpeg'
    },
    {
        name: 'Partner 3',
        image: 'images/dellemc.png'
    },
    {
        name: 'Partner 4',
        image: 'images/fkaifa.png'
    },
    {
        name: 'Partner 5',
        image: 'images/hp.svg'
    },
    {
        name: 'Partner 6',
        image: 'images/idirect.jpeg'
    },
    {
        name: 'Partner 6',
        image: 'images/logo-SAP.jpeg'
    },
    {
        name: 'Partner 6',
        image: 'images/microsoft.jpeg'
    },
    {
        name: 'Partner 6',
        image: 'images/motorola.svg'
    },
    {
        name: 'Partner 6',
        image: 'images/oracle.png'
    },
    {
        name: 'Partner 6',
        image: 'images/siemensenergy.jpeg'
    },
    {
        name: 'Partner 6',
        image: 'images/talend.png'
    }
]
export default function Partners({image}){
  const ref = useRef(null)
  return (
    <>
        <div id="partner" className="container-fluid mb-8" style={{width: '90%'}} >
        <h1 className='text-slate-500 font-medium mb-3 text-5xl text-center mt-16'>OUR PARTNER</h1>
        <img className='m-auto' src='images/partners2.gif' alt="partners" />
        {/* <Carousel 
            responsive={responsive} 
            autoPlay={true} 
            infinite={true} 
            transitionDuration={600} 
            dotListClass="custom-dot-list-style" 
            containerClass="carousel-container" 
            removeArrowOnDeviceType={["tablet", "mobile"]} 
            itemClass="carousel-item-padding-40-px"
        >
            {partners.map(p => 
                <div className="justify-center xl:border-r lg:border-r border-gray-200 xl:pt-10 items-center pr-0">
                    <img tabindex="0" className="focus:outline-none" src={p.image} alt="GS1" role="img" />
                </div>)
            }
        </Carousel> */}
        </div>
    </>
    
   
  );
};
