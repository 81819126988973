import React, { useMemo } from 'react';
import { MDBRipple } from 'mdb-react-ui-kit';

import './Service.css';

export default function Service({ service }) {
  // const content = useMemo(() => service.description.split(","), service)
  return (
    <div class="wrapper  antialiased text-gray-900 py-5">
      <div className='hover:shadow-2xl transform duration-200'>
        <img src={service.image} alt=" random imgee" class="w-full object-cover object-center rounded-xl shadow-md h-64" />
        <div class="relative px-4 -mt-16  ">
          <div class="bg-white p-6 rounded-lg shadow-lg">
            <h3 class="mt-1 text-xl font-semibold uppercase leading-tight truncate text-teal-600 text-md font-semibold">{service.title}</h3>
            <div class="mt-4">
            <ul class="w-full max-w-lg">
               <li aria-expanded="true" class="border-b border-gray-200 group">
               <button class="flex w-full justify-between py-2 px-2 bg-gray-100 hover:bg-gray-200">
                  <span> Détails </span>
                  <span>
                     <svg class="h-6 fill-current text-gray-500 transform -rotate-90" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" className="v-icon__svg"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path></svg>
                  </span>
               </button>
               <div class="px-2 bg-gray-50  h-0 overflow-hidden group-hover:h-full transition-height">
                  <ul class="list-group ">
                    {service.content.map(c => <li className="list-group-item">{c}</li>)}
                  </ul>
               </div>
               </li>
             </ul>
              
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}